import * as React from "react"
import styled from "styled-components"

const StyledBlogTitle = styled.h1`
  padding: 0px;
  margin: 0 0 10px 0;
  color: #ccd6f6;
`
const StyledBlogCard = styled.div``

const BlogCard = ({ props }) => {
  const data = props
  const { title, created_at, img, author } = data
  const linkTo = "https://www.google.ca"
  return (
    <StyledBlogCard href={linkTo}>
      <StyledBlogTitle>{title}</StyledBlogTitle>
      <p>{created_at}</p>
      <p>By: {author}</p>
    </StyledBlogCard>
  )
}

export default BlogCard
