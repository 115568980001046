import * as React from "react"
import styled from "styled-components"
import BlogCard from "../BlogCard"

const StyledTitle = styled.h1`
  padding: 0.1em;
  margin: 0 auto;
  color: #16242f;
`

const BlogContainer = styled.div`
  flex-direction: column;
  align-items: flex-start;
`

const BlogsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 15px;
  position: relative;
`


const BlogItemInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  padding: 25px;
  height: 100%;
  border-radius: 5px;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  background-color: #172a45;
`
const BlogItem = styled.div`
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  &:hover,
  &:focus {
    ${BlogItemInner} {
      transform: translateY(-5px);
      box-shadow: 0 2px 4px rgba(2, 12, 27, 0.9);
      box-shadow: 0 19px 38px #01050b 0 15px 12px rgba(2, 12, 27, 0.9);
    }
  }
`

const BlogSection = ({ children }) => {
  const data = children
  return (
    <BlogContainer>
      <StyledTitle>blog</StyledTitle>
      <BlogsGrid>
        {data.map((item, i) => {
          return (
            <a href="https://www.google.ca" target="_blank">
              <BlogItem>
                <BlogItemInner>
                  <BlogCard props={item} />
                </BlogItemInner>
              </BlogItem>
            </a>
          )
        })}
      </BlogsGrid>
    </BlogContainer>
  )
}

export default BlogSection
