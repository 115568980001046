import * as React from "react"
import { Link } from "gatsby"
import BlogSection from "../components/Sections/BlogSection"
import Layout from "../components/Layout"

const data = [
  {
    title: "Intro",
    created_at: "2022-08-22",
    img: "https://picsum.photos/id/54/400/300",
    author: "Nancy Wheeler",
  },
  {
    title: "Intro1",
    created_at: "2022-08-22",
    img: "https://picsum.photos/id/54/400/300",
    author: "Nancy Wheeler",
  },
  {
    title: "Intro2",
    created_at: "2022-08-22",
    img: "https://picsum.photos/id/54/400/300",
    author: "Nancy Wheeler",
  },
  {
    title: "Intro3",
    created_at: "2022-08-22",
    img: "https://picsum.photos/id/54/400/300",
    author: "Nancy Wheeler",
  },
]

const Blog = () => (
  <Layout>
    <BlogSection children={data} />
  </Layout>
)

export default Blog
